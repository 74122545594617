import React, { useState, useContext, useEffect } from 'react';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import {
  moreInfo,
  back,
  checkOut,
} from '../../texts.json';

import { CartContext } from '../../state/CartContext';

import {
  ModalBase,
  ProductContent,
  CloseButton,
  HasItemImage,
  NextButton,
  PrevButton,
  Image,
  ProductImage,
  NameContainer,
  ProductName,
  InfoContent,
  InfoButton,
  InformationIcon,
  ProductAddContainer,
  CountContainer,
  ChangeNumberButton,
  AddProductButton,
  ChangeNumberImage,
  AddText,
  AddTextWithoutPrice,
  AddPrice,
  CheckoutButton,
  CheckoutText,
  ImageCarouselContainer,
  NutritionalTableModal,
  NutritionalTableImage,
  BackIcon,
  CheckoutIcon,
} from './styles';

import closeIcon from './Assets/close.png';
import prevIcon from './Assets/ic-prev.svg';
import nextIcon from './Assets/ic-next.svg';
import addImage from './Assets/add.png';
import removeImage from './Assets/remove.png';
import hasItemIcon from './Assets/hasItem.png';
import backIcon from './Assets/back.png';
import checkoutImage from './Assets/credit-card-2@3x.png';
import { useUserContext } from '../../state/UserContext';

const DETAIL_TAB = 'detail';
const INFO_TAB = 'info';

function ProductModal({
  product,
  active,
  setActive,
  nextId,
  prevId,
  setRedirect,
}) {
  const handleNext = () => setActive(nextId);
  const handlePrev = () => setActive(prevId);
  const [userInfo] = useUserContext();

  const productSlot = userInfo?.machine?.slots?.find((slot) => (
    slot?.itemId?.productId?._id === product.productId._id
  ));

  const { cartState, cartDispatch } = useContext(CartContext);

  const [tab, setTab] = useState(DETAIL_TAB);
  const isDetail = tab === DETAIL_TAB;
  const isInfo = tab === INFO_TAB;

  const [amountToAdd, setAmountToAdd] = useState(1);
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const handleAddToCart = () => {
    cartDispatch({
      type: 'ADD_AMOUNT',
      value: product.productId._id,
      itemId: product._id,
      amount: amountToAdd,
      price: product.price,
      itemInfo: product,
    });
    setActive(null);
    setAmountToAdd(1);
    goToCart();
  };

  const handleClose = () => {
    setTab(DETAIL_TAB);
    setActive(null);
  };

  const goToCart = () => setRedirect('/carrinho');

  const setInfo = () => setTab(INFO_TAB);
  const setDetail = () => setTab(DETAIL_TAB);

  const productHasInfo = (product.nutritionInfo != null) && (product.nutritionInfo !== '');

  const isCurrentProductAdded = !!cartState[product.productId];

  useEffect(() => {
    const addedProducts = Object.entries(cartState).filter(([, eachProduct]) => (
      eachProduct.amount > 0
    ));

    setIsCartEmpty(!addedProducts.length);
  }, [cartState]);

  const [shouldNutritionalTableShow, setShouldNutritionalTableShow] = useState(false);

  return (
    <ModalBase active={active === product._id}>
      <CloseButton type="button" onClick={handleClose}>
        <Image src={closeIcon} />
      </CloseButton>
      {isDetail
      && (
      <>
        {isCurrentProductAdded && <><HasItemImage src={hasItemIcon} /></>}

        <ProductContent>

          <NameContainer>
            <ProductName>{product.name}</ProductName>

            {/* <InformationIcon
              onClick={() => setShouldNutritionalTableShow(true)}
            >
              i
            </InformationIcon> */}
          </NameContainer>

          <ImageCarouselContainer>
            <ProductImage src={product.image} />

            <NextButton onClick={handleNext}>
              <Image src={nextIcon} />
            </NextButton>

            <PrevButton onClick={handlePrev}>
              <Image src={prevIcon} />
            </PrevButton>
          </ImageCarouselContainer>

          {/*
          <PriceTag>
            <PriceTag.Text>
              {'R$ '.concat((product?.price || 0).toFixed(2))}
            </PriceTag.Text>
          </PriceTag> */}

          <ProductAddContainer>
            <CountContainer>
              <ChangeNumberButton
                onClick={() => {
                  setAmountToAdd(amountToAdd - 1 ? amountToAdd - 1 : 1);
                }}
              >
                <ChangeNumberImage src={removeImage} alt="Remover" />
              </ChangeNumberButton>
              {amountToAdd}
              <ChangeNumberButton
                onClick={() => {
                  if (userInfo?.machine == undefined) {
                    setAmountToAdd(amountToAdd + 1);
                  } else if (userInfo?.machine.type === 'Totemach') {
                    setAmountToAdd(amountToAdd + 1);
                  } else {
                    if (amountToAdd < productSlot.quantity) {
                      setAmountToAdd(amountToAdd + 1);
                    }
                  }                
                }}
              >
                <ChangeNumberImage src={addImage} alt="Adicionar" />
              </ChangeNumberButton>
            </CountContainer>

            <AddProductButton
              onClick={handleAddToCart}
            >
              {userInfo?.machine?.sampling === true && (
                <AddTextWithoutPrice>Adicionar</AddTextWithoutPrice>
              )}
              {userInfo?.machine?.sampling !== true && (
                <>
                  <AddText>Adicionar</AddText>
                  <AddPrice>{'R$ '.concat((product?.price * amountToAdd || 0).toFixed(2))}</AddPrice>
                </>
              )}
            </AddProductButton>
          </ProductAddContainer>

          {productHasInfo
            && (
              <InfoButton onClick={setInfo}>
                {moreInfo}
                <ArrowRightOutlined />
              </InfoButton>
            )}
        </ProductContent>

        {/* {!outOfStock
        && (
        <BottomButtonsContainer>
          <ActionButton onClick={handleBuyNow}>
            <ButtonText>{buyNow}</ButtonText>
          </ActionButton>

          <ActionButton onClick={handleAddToCart}>
            <ButtonText>{addToCartText}</ButtonText>
          </ActionButton>
        </BottomButtonsContainer>
        )} */}

        {shouldNutritionalTableShow && (
          <NutritionalTableModal>
            <BackIcon src={backIcon} onClick={() => setShouldNutritionalTableShow(false)} />

            <ProductName>{product.name}</ProductName>

            <NutritionalTableImage src={product.nutritionalInfo} />
          </NutritionalTableModal>
        )}

        {!isCartEmpty && (
          <CheckoutButton onClick={goToCart}>
            <CheckoutIcon src={checkoutImage} />
            <CheckoutText>{checkOut}</CheckoutText>
          </CheckoutButton>
        )}
      </>
      )}

      {isInfo
      && (
        <InfoContent>
          <InfoButton onClick={setDetail}>
            <ArrowLeftOutlined />
            {back}
          </InfoButton>

          <InfoContent.Title>
            {product.name}
          </InfoContent.Title>
          <InfoContent.Table src={product.nutritionInfo} />
        </InfoContent>
      )}
    </ModalBase>
  );
}

export default ProductModal;

import React from "react";
import InputMask from "react-input-mask";
import Modal from "../../../components/Modal";
import { CardModal } from "../styles";

export default function CreditCardModal({
    handleSubmit,
    active,
    setActive,
    cardInfoState,
    title
}) {
    return (
        <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
            <Modal.Title>{title}</Modal.Title>
            <Modal.Label>Só é possível cadastrar um cartão.</Modal.Label>
            <InputMask
              mask="9999 9999 9999 9999"
              pattern="[0-9]*"
              maskChar=""
              type='tel'
              size="large"
              name="number"
              placeholder="Número do cartão"
              value={cardInfoState.state.cardNumber}
              onChange={(e) => cardInfoState.setState({...cardInfoState.state, cardNumber: e.target.value})}
            >
              {(inputProps) => <Modal.Input {...inputProps} />}
            </InputMask>
            <Modal.Input
              size="large"
              name="name"
              id="name"
              placeholder="Nome do titular"
              value={cardInfoState.state.name}
              onChange={(e) => cardInfoState.setState({...cardInfoState.state, name: e.target.value})}
            />
          <CardModal>
            <InputMask
              mask="99/99"
              pattern="[0-9]*"
              type='tel'
              maskChar=""
              size="large"
              name="expiration"
              id="expiration"
              placeholder="Validade"
              value={cardInfoState.state.expiration}
              onChange={(e) => cardInfoState.setState({...cardInfoState.state, expiration: e.target.value})}
            >
              {(inputProps) => <Modal.Input {...inputProps} />}
            </InputMask>
            <InputMask
              mask="9999"
              pattern="[0-9]*"
              type='tel'
              maskChar=""
              size="large"
              name="cvv"
              id="cvv"
              placeholder="CVV"
              value={cardInfoState.state.cvv}
              onChange={(e) => cardInfoState.setState({...cardInfoState.state, cvv: e.target.value})}
            >
              {(inputProps) => <Modal.Input {...inputProps} />}
            </InputMask>
          </CardModal>
            <InputMask
              mask= "999.999.999-99"
              pattern="[0-9]*"
              type= 'tel'
              maskChar= ""
              size="large"
              name="holder_document"
              id="holder_document"
              placeholder="CPF do Titular"
              value={cardInfoState.state.holderDocument}
              onChange={(e) => cardInfoState.setState({...cardInfoState.state, holderDocument: e.target.value})}
            >
              {(inputProps) => <Modal.Input {...inputProps}/>}
            </InputMask>
            <Modal.Button>Cadastrar</Modal.Button>
        </Modal>
    )
}
import styled from 'styled-components';
import { Modal, Checkbox } from 'antd';
import { CheckCircleFilled, TagOutlined } from '@ant-design/icons';

import Colors from '../../styles/Colors';
import EmbuedInput from '../VirtualKeyboard/components/EmbuedInput';
import EmbuedNumberInput from '../VirtualKeyboard/components/EmbuedNumberInput';

const CheckoutContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 27rem;
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  padding-left: 3.7rem;
  padding-right: 3.7rem;
  background-color: ${Colors.background};

  @media (max-width: 1024px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    align-items: center;
  }
`;

const DarkDivider = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: ${Colors.secondaryBlack};
`;

const DiscountItemContainer = styled.div`
  grid-area: 1;

  @media (max-width: 1024px) {
    height: fit-content;
    margin-bottom: 10px;
    width: 90%;
  }
`;

const DiscountTotalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 15.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    height: fit-content;
    margin-bottom: 10px;
    width: 90%;
  }
`;

const PriceContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    height: fit-content;
    margin-bottom: 10px;
    width: 90%;
  }
`;

const PriceContainerDiscount = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    height: fit-content;
    margin-bottom: 5px;
    width: 100%;
  }
`;

const Text = styled.h2`
  font-size: 2.8rem;
  letter-spacing: .14rem;
  color: ${Colors.black};
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
    height: fit-content;
  }

  @media (max-width: 320px) {
    font-size: 0.9rem;
    height: fit-content;
  }
`;

const TextSubtotal = styled.h2`
  font-size: 2rem;
  letter-spacing: .14rem;
  color: ${Colors.secondaryBlack};
  margin: 0;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 1rem;
    height: fit-content;
  }

  @media (max-width: 320px) {
    font-size: 0.7rem;
    height: fit-content;
  }
`;

const TextDiscount = styled.h2`
  font-size: 2rem;
  letter-spacing: .14rem;
  color: red;
  margin: 0;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 1rem;
    height: fit-content;
  }

  @media (max-width: 320px) {
    font-size: 0.7rem;
    height: fit-content;
  }
`;

const CheckoutButton = styled.button`
  height: 10rem;
  width: 100vw;
  border: none;
  box-shadow: 0 -.4rem .56rem 0 ${Colors.mainBlack};
  background-image: ${Colors.gradiendBackground};
  margin-left: -3.7rem;
  margin-right: -3.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    height: fit-content;
    padding: 15px 0;
    width: 100%;
    margin: 0;
  }
`;

const ButtonText = styled.p`
  font-size: 2.7rem;
  text-transform: uppercase;
  color: ${Colors.mainWhite};
  text-align: center;
  margin: 0;
  text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
  margin-left: 2rem;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
  }
`;

const CardIcon = styled.img`
  height: 5.55rem;

  @media(max-width: 1024px) {
    height: 25px;
    margin-right: 20px;
  }
`;

const DiscountButton = styled.button`
  color: ${Colors.secondaryBlue};
  background: transparent;
  border: none;
  padding-bottom: 0;
  text-decoration: underline;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: 5%;
  font-size: 1.2rem;

  @media(min-width: 1024px) {
    font-size: 2.4rem;
    margin-left: 0;
  }

  @media(max-width: 320px) {
    font-size: 0.9rem;
  }
`;

const DiscountContainer = styled.div`
  background: transparent;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  @media(min-width: 1024px) {
    width: 100%;
  }
`;

const DiscountSubContainer = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: bold;

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }

  @media(min-width: 1024px) {
    font-size: 2.4rem;
  }
`;

const DiscountError = styled.p`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  color: red;
  margin: 0;
  text-align: center;

  @media(min-width: 1024px) {
    font-size: 2rem;
  }
`;

const DiscountInput = styled(EmbuedInput)`
  border-radius: 5px;
  border: 1px solid ${Colors.mainBlack};

  @media(min-width: 1024px) {
    font-size: 2.4rem;
    border-radius: 10px;
    padding: 10px;
  }
`;

const DiscountConfirm = styled.button`
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(to top, #0066b5, #6cc2ff);
  color: #fff;
  padding: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;

  @media(min-width: 1024px) {
    font-size: 2.4rem;
    margin-left: 25px;
    border-radius: 10px;
    padding: 10px;
  }
`;

const CheckIcon = styled(CheckCircleFilled)`
  margin-left: 5px;

  @media(min-width: 1024px) {
    margin-left: 15px;
  }
`;

const TagIcon = styled(TagOutlined)`
  font-size: 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;

  @media(min-width: 1024px) {
    font-size: 40px;
  }
`;

const EmailModal = styled(Modal)`
  width: 90% !important;
  margin: auto;

  .ant-modal-content {
    border-radius: 15px !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .ant-btn-background-ghost {
    background: transparent !important;
    text-shadow: none !important;
    border: none !important;
    color: transparent !important;
  }

  .ant-modal-footer {
    padding: 0 !important;
  }

  @media (min-width: 1024px) {
    width: 80vw !important;
    min-height: 400px !important;
  }
`;

const ModalTitle = styled.h3`
  text-align: center;
  width: 80%;
  font-weight: bold;
  margin: 0;
  margin-top: 30px;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
`;

const ModalForm = styled.form`
  width: 90%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    min-height: 600px !important;
  }
`;

const InputModal = styled(EmbuedInput)`
    width: 100%;
    border-radius: 5px;

  @media (min-width: 1024px) {
    border-radius: 15px;
    width: 90%;
    font-size: 2.5rem !important;
  }
`;

const InputNumberModal = styled(EmbuedNumberInput)`
    width: 100%;
    border-radius: 5px;

  @media (min-width: 1024px) {
    border-radius: 15px;
    width: 90%;
    font-size: 2.5rem !important;
  }
`;

const NewsLetterCointainer = styled.div`
  width: 100%;
  margin-bottom: 70px;
  display: flex;
  margin-top: 20px;

  @media (min-width: 1024px) {
    margin-top: 30px;
    font-size: 2rem;
    margin-bottom: 100px;
    width: 90%;
  }
`;

const NewsLetterCheckbox = styled(Checkbox)`
  margin-right: 10px;
  margin-top: 20px;
  margin-right: 10px;

  @media (min-width: 1024px) {
    margin-right: 30px;

    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
    }
  }
`;

const OkButton = styled.button`
  background-image: linear-gradient(to top, #00acea, #00efd1);
  border-radius: 15px 0 15px 0;
  font-size: 1.2rem;
  border: none;
  color: #fff;
  padding: 10px 30px;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (min-width: 1024px) {
    font-size: 2rem;
    padding: 13px 55px;
  }

  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 3px 10px;
  }
`;

const CancelButton = styled.button`
  background-image: unset;
  background-color: #eee;
  border-radius: 0 15px 0 15px;
  font-size: 1.2rem;
  border: none;
  color: #888;
  padding: 10px 30px;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (min-width: 1024px) {
    font-size: 2rem;
    padding: 13px 55px;
  }

  @media (max-width: 900px) {
    font-size: 1rem;
    padding: 3px 10px;
  }
`;

const LabelModal = styled.span`
  color: ${Colors.secondaryBlue};
  font-weight: bold;
  display: block;
  margin-right: auto;
  margin-top: 20px;
  font-size: 0.9rem;

  @media(min-width: 1024px) {
    font-size: 2rem;
    margin-left: 5%;
  }
`;

const PanelDiscount = styled.div`
  justify-content-items: space-between;
  display:flex;
  flex-direction: column;
  width: 90%;

  @media (min-width: 1080px) {
    width: 100%;
  }
`;

export {
  DarkDivider,
  PriceContainer,
  Text,
  TextSubtotal,
  TextDiscount,
  CheckoutButton,
  ButtonText,
  CheckoutContainer,
  CardIcon,
  DiscountButton,
  DiscountContainer,
  DiscountSubContainer,
  DiscountError,
  DiscountInput,
  DiscountConfirm,
  CheckIcon,
  TagIcon,
  EmailModal,
  ModalTitle,
  InputModal,
  InputNumberModal,
  LabelModal,
  ModalForm,
  NewsLetterCheckbox,
  NewsLetterCointainer,
  OkButton,
  CancelButton,
  DiscountItemContainer,
  DiscountTotalContainer,
  PriceContainerDiscount,
  PanelDiscount
};
